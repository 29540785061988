import { Col, Divider, Popover, Row, Table } from "antd";
import { ValueType } from "../../types/AttributesAnalytics.type";
import { InfoCircleOutlined } from "@ant-design/icons";
import { checkStatus } from "./CheckStatus";

interface ValueTableProps {
    attribute: { values: ValueType[] };
}

const ValueTable = (props: ValueTableProps) => {
    const { attribute } = props;
    return (
        <Table
            bordered
            dataSource={attribute.values}
            columns={[
                {
                    dataIndex: "value",
                    width: "300px",
                    render: (text, attribute) => (
                        <div>
                            <span>{text}</span>
                            {attribute.pra_clean_value && <span style={{ marginLeft: "12px", color: "gray" }}>({attribute.pra_clean_value})</span>}
                        </div>
                    ),
                },

                {
                    dataIndex: "",
                    width: "60px",
                    align: "center" as "center",
                    render: (_, attribute) => (
                        <div className="attributes-analytics-count" style={{ marginRight: "7px" }}>
                            {attribute.price_attribute_count}
                        </div>
                    ),
                },
                {
                    dataIndex: "allowed_values",
                    align: "center" as "center",
                    width: "60px",
                    render: (text, record) => {
                        const allowValues = text ? JSON.parse(text) : [];
                        return (
                            <Popover
                                placement="rightTop"
                                title={record.attribute_type === "range" ? "Range" : "Allowed Values"}
                                open={record.is_mapped_key ? undefined : false}
                                content={
                                    <div>
                                        <Divider style={{ margin: "0 0 0.5rem 0" }} />
                                        {record.attribute_type === "range" ? (
                                            <div>{record.ranges}</div>
                                        ) : (
                                            <Row gutter={16}>
                                                {allowValues.map((value: string) => (
                                                    <Col className="gutter-row" span={8} key={value}>
                                                        <div
                                                            style={{
                                                                padding: "2px 6px",
                                                                borderRadius: "5px",
                                                                textAlign: "center",
                                                                marginRight: "1rem",
                                                                marginBottom: "1rem",
                                                                color: record.value === value ? "green" : "gray",
                                                            }}
                                                        >
                                                            {value}
                                                        </div>
                                                    </Col>
                                                ))}
                                            </Row>
                                        )}
                                    </div>
                                }
                            >
                                <InfoCircleOutlined />
                            </Popover>
                        );
                    },
                },
                {
                    dataIndex: "id",
                    width: "225px",
                    render: (_, record) => checkStatus(record),
                },
            ]}
            pagination={false}
            style={{ marginLeft: "3rem", width: "650px" }}
        />
    );
};

export default ValueTable;
