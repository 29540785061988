import React from "react";
import { Row, Col, Form, Divider } from "antd";
import InputE from "../../../shared/FormElements/Input";
import { useDispatch } from "react-redux";
import { userLogout } from "../../../actions/userAction/userAction";
import { USERS_LOGIN } from "../../../actions/userAction/userActionsType";
import { useHistory } from "react-router-dom";
import { useWindowSize } from "../../../shared/hooks/UseWindowSize";
import "../style/Login.css";
import { getAllCountries } from "../../../actions/countries/countriesAction";
import axios from "axios";
import authCheckStatus from "../../../shared/utils/authSeviceChecked";
import showMessage from "../../../shared/MessagesInfo/message";
import { removeLSCurrentCountry, setLSCurrentCountry } from "../../../actions/LsActions/localeStorageActions";

const Login: React.FC = () => {
    const [form] = Form.useForm();
    const dispatch = useDispatch();

    const history = useHistory();
    const windowSize = useWindowSize();

    const handleSubmit = (e: any) => {
        loginUser(USERS_LOGIN, e, history);
        form.resetFields();
    };

    const loginUser = async (typeAction: any, user: any, history: any) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_URL_API}/auth/login`, user);
            //authCheckStatus(response.status)
            const data = response.data;
            //const countriesArr = countries ?  countries.map( c => c.name) : []
            if (data.success) {
                localStorage.setItem("token", data.success.token);
                //localStorage.setItem('countries', JSON.stringify(countriesArr))
                //localStorage.setItem('currentCountry', countries[0])
                const tokenExpirationDate = new Date(new Date().getTime() + 1000 * 60 * 60 * 10);

                localStorage.setItem("tokenExpirationDate", JSON.stringify(tokenExpirationDate));
                localStorage.setItem("loggedInTime", JSON.stringify(new Date()));
                const lsObj = {
                    currentCountry: "rs",
                    countryId: 1,
                };
                localStorage.setItem("country", JSON.stringify(lsObj));
            }
            showMessage(data.message);
            dispatch({ type: typeAction, payload: { ...data, token: data.success.token, tokenExpirationDate: new Date().getTime() + 1000 * 60 * 60 * 10 } });
            //dispatch({ type: SET_LS_CURRENT_COUNTRY, payload:{currentCountry:"rs", countryId:1}})
            dispatch(setLSCurrentCountry("rs", 1));
            dispatch(getAllCountries());
            history.push("/");
        } catch (err: any) {
            if (err.response) {
                if (authCheckStatus(err.response.status)) {
                    //setLsObject({currentCountry: 'rs', countryId: 1})
                    dispatch(userLogout());
                    /////////
                    dispatch(removeLSCurrentCountry());
                }
            }
        }
    };

    return (
        <Row justify="center">
            <Col span={windowSize.width > 1830 ? 5 : windowSize.width > 1270 ? 8 : 10}>
                <Divider>Login</Divider>
                <Form form={form} name="login-user-form" onFinish={handleSubmit} className="login-user-box">
                    <InputE label="E-mail" name="email" rules={[{ required: true, message: "Missing email" }]} />
                    <InputE label="Password" name="password" rules={[{ required: true, message: "Missing password" }]} />
                    <Divider></Divider>
                    <button className="u-button" type="submit" style={{ padding: "5px 10px" }}>
                        Login
                    </button>
                </Form>
            </Col>
        </Row>
    );
};

export default Login;
