import { Progress, Spin, Table } from "antd";
import ShopTable from "./ShopTable";
import { CategoryType } from "../../types/AttributesAnalytics.type";
import ExpandIcon from "./ExpandIcon";
import { showShops } from "../../api/AttributesAnalytics";

interface CategoryTableProps {
    data: CategoryType[];
    setData: React.Dispatch<React.SetStateAction<CategoryType[]>>;
    loading: boolean;
}

const CategoryTable = (props: CategoryTableProps) => {
    const { data, loading, setData } = props;

    return (
        <Table
            bordered
            dataSource={data}
            columns={[
                {
                    dataIndex: `category_name`,
                    key: "name",
                    width: "300px",
                    render: (text, record) => text && <span>{text}</span>,
                },
                {
                    dataIndex: `total`,
                    key: "name",
                    width: "100px",
                    align: "center" as "center",
                    render: (text) => text && <div className="attributes-analytics-count">{text}</div>,
                },
                {
                    dataIndex: "shop_name",
                    align: "center" as "center",
                    width: "100px",
                    render: (_, record) => (
                        <div style={{ display: "flex", flexDirection: "column", width: "120px" }}>
                            <span>Mapped</span>
                            <span>{`${record.count_is_mapped}/${record.total}`}</span>
                        </div>
                    ),
                },
                {
                    dataIndex: "shop_name",
                    align: "center" as "center",
                    render: (_, record) => (
                        <div style={{ display: "flex", flexDirection: "column", width: "130px" }}>
                            <span>Passing</span>
                            <span>{`${(record.count_is_allowed_value ?? 0) + (record.count_is_in_range ?? 0)}/${record.total}`}</span>
                            <Progress percent={Math.round((((record.count_is_allowed_value ?? 0) + (record.count_is_in_range ?? 0)) / record.total) * 100)} />
                        </div>
                    ),
                },
            ]}
            expandable={{
                expandIcon: ({ expanded, onExpand, record }) => record.attribute_category_id && <ExpandIcon expanded={expanded} onExpand={onExpand} record={record} />,
                onExpand: (expanded, category) => showShops(expanded, category, setData),
                expandedRowRender: (category) => (category.shops.length === 0 ? <Spin style={{ marginLeft: "5rem" }} /> : <ShopTable {...props} category={category} />),
                rowExpandable: (record) => record.name !== "Not Expandable",
                expandedRowClassName: () => "attributes-analytics-expanded-row",
            }}
            loading={loading}
            rowClassName={(category) => (category.isOpen ? "shop-attributes-open-row no-row-hover row-bold-text" : "")}
            pagination={{ pageSize: 100, showSizeChanger: false }}
        />
    );
};

export default CategoryTable;
