import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, Form, Image, Input, InputRef, Spin, Upload } from "antd";
import type { FormInstance } from "antd/es/form";
import showMessage from "../../../../shared/MessagesInfo/message";
import axios from "axios";
import openNotification from "../../../../shared/MessagesInfo/WarningBox";
import { DeleteTwoTone, FontSizeOutlined, Loading3QuartersOutlined } from "@ant-design/icons";
import { BrandType } from "../../pages/AllBrands";
import { slugify } from "../../../../shared/utils/slugify";
import { formatString } from "../../../../shared/utils/formatString";

interface EditableCellProps {
    title: React.ReactNode;
    editable: boolean;
    children: React.ReactNode;
    dataIndex: keyof BrandType;
    record: BrandType;
    handleSave: (record: BrandType) => void;
    setAllBrands: React.Dispatch<React.SetStateAction<BrandType[]>>;
}

const EditableContext = React.createContext<FormInstance<any> | null>(null);

interface EditableRowProps {
    index: number;
}

export const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
    const [form] = Form.useForm();
    return (
        <Form form={form} component={false}>
            <EditableContext.Provider value={form}>
                <tr {...props} />
            </EditableContext.Provider>
        </Form>
    );
};

const nnId = 3621;

export const EditableCell: React.FC<EditableCellProps> = ({ title, editable, children, dataIndex, record, handleSave, setAllBrands, ...restProps }) => {
    const [editing, setEditing] = useState(false);
    const [loading, setLoading] = useState(false);
    const [logoLoading, setLogoLoading] = useState(false);
    const [loadingCorrectString, setLoadingCorrectString] = useState(false);
    const [logoDeleteLoading, setLogoDeleteLoading] = useState(false);
    const inputRef = useRef<InputRef>(null);
    const form = useContext(EditableContext)!;

    useEffect(() => {
        if (editing && dataIndex === "name") {
            inputRef.current!.focus();
        }
    }, [editing, dataIndex]);

    const toggleEdit = () => {
        setEditing(!editing);
        form.setFieldsValue({ [dataIndex]: record[dataIndex] });
    };

    //////////////////////////------------- for image -------------------

    const changeImage = async ({ file, onSuccess }: any, type: string) => {
        if (type === "set") {
            if (file) {
                const type = file.type.split("/");
                if (type[1] === "jpeg" || type[1] === "jpg" || type[1] === "png" || type[1] === "webp") {
                    const formData: any = new FormData();
                    formData.append("id", record.id);
                    formData.append("image", file);
                    setLogoLoading(true);
                    try {
                        const { data } = await axios.post(`${process.env.REACT_APP_URL_API}/product/brand/update`, formData); //????????
                        setAllBrands((curr) => curr.map((b) => (b.id === record.id ? { ...b, logo_url: data.data.logo_url } : b)));
                        toggleEdit();
                        showMessage(data.message);
                        setLogoLoading(false);
                    } catch (err) {
                        console.log(err);
                    }
                }
            } else {
                openNotification(`Allowed image extensions JPG, JPEG, WEBP and PNG`);
            }
        } else {
            const formData: any = new FormData();
            formData.append("id", record.id);
            formData.append("image", null);
            setLogoDeleteLoading(true);
            try {
                const { data } = await axios.post(`${process.env.REACT_APP_URL_API}/product/brand/update`, { id: record.id, logo_url: null }); //????????
                setAllBrands((curr) => curr.map((b) => (b.id === record.id ? { ...b, logo_url: null } : b)));
                toggleEdit();
                showMessage(data.message);
                setLogoDeleteLoading(false);
            } catch (err) {
                console.log(err);
            }
            ////////////
        }
    };

    /////////////////////////////

    const save = async () => {
        if (dataIndex === "name") {
            try {
                const values = await form.validateFields();
                if (record.name?.trim() === values.name?.trim()) {
                    // is new mpn is the same as previous don't call endpoint
                    toggleEdit();
                    return;
                }
                if (values.name.trim().length > 0) {
                    setLoading(true);
                    try {
                        const { data } = await axios.post(`${process.env.REACT_APP_URL_API}/product/brand/update`, { id: record.id, name: values.name });
                        showMessage(data.message);
                        toggleEdit();
                        handleSave({ ...record, ...values });
                        setLoading(false);
                    } catch (err) {
                        console.log(err);
                    }
                } else {
                    openNotification("Brand name should have min one character");
                    toggleEdit();
                }
            } catch (errInfo) {
                console.log("Save failed:", errInfo);
                openNotification("Save failed");
            }
        }
    };

    const correctName = async (rowRecord: BrandType) => {
        if (record.name) {
            setLoadingCorrectString(true);
            try {
                const { data } = await axios.post(`${process.env.REACT_APP_URL_API}/product/brand/update`, { id: record.id, name: formatString(rowRecord.name) });
                showMessage(data.message);
                setAllBrands((curr) => curr.map((b) => (b.id === rowRecord.id ? { ...b, name: formatString(rowRecord.name) } : b)));
                setLoadingCorrectString(false);
            } catch (err) {
                console.log(err);
                setLoadingCorrectString(false);
            }
        }
    };

    let childNode = children;

    if (editable && dataIndex === "name") {
        childNode = editing ? (
            <Form.Item style={{ margin: 0 }} name={dataIndex}>
                <Input ref={inputRef} onPressEnter={save} onBlur={save} suffix={loading ? <Spin size="small" indicator={<Loading3QuartersOutlined style={{ fontSize: 14 }} spin />} /> : <span />} />
            </Form.Item>
        ) : (
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div
                    className="editable-cell-value-wrap"
                    style={{ paddingRight: 24, width: "100%", cursor: record.id === nnId ? "default" : "pointer" }}
                    onClick={record.id !== nnId ? toggleEdit : undefined}
                >
                    {children}
                </div>
                <Button size="small" shape="round" onClick={() => correctName(record)} loading={loadingCorrectString} icon={<FontSizeOutlined />} disabled={record.id === nnId} />
            </div>
        );
    }
    if (editable && dataIndex === "logo") {
        childNode = (
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                <Form.Item name="image" label="" style={{ padding: "0", margin: "0" }}>
                    <Upload
                        //name="image"
                        customRequest={(options) => changeImage(options, "set")}
                        //onChange={handleUpload}
                        showUploadList={false}
                        fileList={undefined}
                    >
                        <Image
                            src={`https://img.ep-cdn.com/i/100/40/${record.logo_url}${record.name ? `/${slugify(record.name)}` : null}.webp`}
                            width={100}
                            alt="brand-logo"
                            preview={false}
                            style={{ cursor: "pointer", position: "relative", border: "1px solid whitesmoke", padding: "2px" }}
                        />
                    </Upload>
                </Form.Item>
                <div style={{ width: "10px", marginLeft: "5px" }}>{logoLoading && <Spin size="small" />}</div>
                <Button
                    size="small"
                    icon={<DeleteTwoTone />}
                    style={{ position: "absolute", right: "5px", top: "17px", cursor: "pointer" }}
                    loading={logoDeleteLoading}
                    onClick={() => changeImage("", "delete")}
                />
            </div>
        );
    }

    return <td {...restProps}>{childNode}</td>;
};

export default EditableCell;
