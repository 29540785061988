import React from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

interface CreateInfoTextProps {
    setEditorText: React.Dispatch<React.SetStateAction<string>>;
    descriptionInit: string;
}

const CreateInfoText: React.FC<CreateInfoTextProps> = ({ setEditorText, descriptionInit }) => {
    const handleEditorChange = (event: any, editor: ClassicEditor) => {
        const data = editor.getData();
        setEditorText(data);
    };

    return <CKEditor editor={ClassicEditor} data={descriptionInit ?? ""} onChange={handleEditorChange} />;
};

export default CreateInfoText;
