import { Modal, Table } from "antd";
import { HistoryType } from "../../pages/ScrapersOverview";
import moment from "moment";

interface HistoryModalProps {
    isModalOpen: boolean;
    handleCancel: () => void;
    history: HistoryType[];
}

const HistoryModal = (props: HistoryModalProps) => {
    const { isModalOpen, handleCancel, history } = props;
    return (
        <Modal title="History" open={isModalOpen} onCancel={handleCancel} footer={false} forceRender={true} width={1000}>
            <Table
                dataSource={history}
                columns={[
                    { title: "Id", dataIndex: "id" },
                    { title: "Number of products", dataIndex: "number_of_products" },
                    { title: "Number of categories", dataIndex: "number_of_categories" },
                    { title: "Execution time", dataIndex: "execution_time" },
                    { title: "Created at", dataIndex: "created_at", render: (text: string) => text && `${moment(text).format("DD MMM YYYY,")} ${moment(text).format("HH:mm")}` },
                ]}
                rowKey={(record) => record.id}
                pagination={{ hideOnSinglePage: true }}
            />
        </Modal>
    );
};

export default HistoryModal;
