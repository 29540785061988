import React, { useEffect, useState } from "react";
import HelmetTitle from "../../../shared/Head/HelmetTitle";
import { Col, Divider, Layout, Row, Statistic } from "antd";
import axios from "axios";
import HistoryModal from "../components/ScrapersOverview/HistoryModal";
import ScrapersOverviewTable from "../components/ScrapersOverview/ScrapersOverviewTable";

export type ScraperType = {
    id: number;
    last_run: string;
    run_at: string;
    shop_id: number;
    website_name: string;
    website_url: string;
};

export type HistoryType = {
    id: number;
    number_of_products: number;
    number_of_categories: number;
    execution_time: string;
    created_at: string;
};

const ScrapersOverview: React.FC = () => {
    const [scrapers, setScrapers] = useState<ScraperType[]>([]);
    const [count, setCount] = useState(0);
    const [history, setHistory] = useState<HistoryType[]>([]);
    const [page, setPage] = useState(1);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    useEffect(() => {
        const getScrapers = async () => {
            try {
                const { data } = await axios.get(`${process.env.REACT_APP_URL_API}/scraper/data/list?page=${page}`);
                setScrapers(data);
            } catch (err) {
                console.log(err);
            }
        };
        getScrapers();
        const getCount = async () => {
            try {
                const { data } = await axios.get(`${process.env.REACT_APP_URL_API}/scraper/data/count`);
                setCount(data);
            } catch (err) {
                console.log(err);
            }
        };
        getCount();
    }, [page]);

    const showHistory = async (id: number) => {
        try {
            const { data } = await axios.get(`${process.env.REACT_APP_URL_API}/scraper/data/history?id=${id}`);
            setHistory(data);

            setIsModalOpen(true);
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <React.Fragment>
            <HelmetTitle title="Data Entry App - Scrapers Overview" />
            <HistoryModal handleCancel={handleCancel} history={history} isModalOpen={isModalOpen} />
            <div style={{ backgroundColor: "white", margin: "0.5rem 1rem", minHeight: "87vh" }}>
                <Divider>Scrapers Overview</Divider>
                <Row justify="center">
                    <Col span={23} style={{ border: "1px solid lightgray", backgroundColor: "whitesmoke", padding: "1rem", display: "flex", position: "relative", flexDirection: "column" }}>
                        <div></div>
                        <div style={{ display: "flex", justifyContent: "end" }}>
                            <Statistic title="Total" value={count} valueStyle={{ color: "green" }} style={{ marginRight: "1rem" }} />
                        </div>
                    </Col>
                    <Col span={16}>
                        <Layout style={{ margin: "0.6rem 1rem" }}>
                            <Layout.Header className="product-img-header" style={{ color: "whitesmoke", backgroundColor: "#5a5a5a", height: "1.9rem", lineHeight: "30px", position: "relative" }}>
                                <span> Primary Attributes </span>{" "}
                            </Layout.Header>
                            <ScrapersOverviewTable scrapers={scrapers} setScrapers={setScrapers} showHistory={showHistory} setPage={setPage} count={count} />
                        </Layout>
                    </Col>
                </Row>
            </div>
        </React.Fragment>
    );
};

export default ScrapersOverview;
