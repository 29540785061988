import React, { useEffect, useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { BrandDescStateType } from "./AllBrandsDescription";

type TextEditorType = {
    country: string;
    editorText: string;
    setBrandDescriptionState: React.Dispatch<React.SetStateAction<BrandDescStateType[]>>;
    containerRef: any;
};

const TextEditor: React.FC<TextEditorType> = (props) => {
    const { country, editorText, setBrandDescriptionState, containerRef } = props;
    const [editorData, setEditorData] = useState(editorText?.toString());

    useEffect(() => {
        setEditorData(editorText);
    }, [editorText]);

    const handleEditorChange = (event: any, editor: ClassicEditor) => {
        const data = editor.getData();
        setEditorData(data);
    };

    const changeDataOnBlur = (event: any, editor: ClassicEditor) => {
        const newText = editor.getData();
        const clearInit = editorText?.toString() ?? "";
        if (clearInit.trim() === newText.trim()) {
            return;
        }
        setBrandDescriptionState((curr) => curr.map((b) => (b.country === country ? { ...b, [`description_${country}`]: editorData } : b)));
    };

    const handleBeforeDestroy = () => {
        const container = containerRef.current;
        if (container) {
            // Zaključavanje dimenzija kontejnera pre uklanjanja
            container.style.height = `${container.offsetHeight}px`;
            container.style.width = `${container.offsetWidth}px`;
            container.style.overflow = "hidden"; // Sprečava prelivanje
        }
    };

    return (
        <div ref={containerRef}>
            <CKEditor
                editor={ClassicEditor}
                data={editorData ?? ""}
                onChange={handleEditorChange}
                onBlur={changeDataOnBlur}
                onReady={(editor) => {
                    // Postavljanje fokusa kad se editor učita
                    editor.editing.view.focus();
                    // Event handler za čišćenje pre uništavanja
                    const originalDestroy = editor.destroy.bind(editor);
                    editor.destroy = () => {
                        handleBeforeDestroy(); // Podesi stilove
                        return originalDestroy();
                    };
                }}
            />
        </div>
    );
};

export default TextEditor;
