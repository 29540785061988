import { Col, Divider, Row } from "antd";
import HelmetTitle from "../../../shared/Head/HelmetTitle";
import React from "react";
import CategoryTable from "../components/AttributesAnalytics/CategoryTable";
import "../style/AttributesAnalytics.css";
import UseGetCategories from "../hooks/AttributesAnalytics/UseGetCategories";

const AttributesAnalytics = () => {
    const { data, setData, loading } = UseGetCategories();

    return (
        <React.Fragment>
            <HelmetTitle title="Data Entry App - Attributes analytics" />
            <div style={{ backgroundColor: "white", margin: "0.5rem 1rem", minHeight: "87vh" }}>
                <Divider>Attributes analytics</Divider>
                <Row justify="center">
                    <Col span={23}>
                        <CategoryTable data={data} loading={loading} setData={setData} />
                    </Col>
                </Row>
            </div>
        </React.Fragment>
    );
};

export default AttributesAnalytics;
