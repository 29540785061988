import { Popover, Spin } from "antd";
import { useState } from "react";
import { PopupPricesProps } from "../types/props";
import { ProductPricesType } from "../types/types";
import { getProductPrices } from "../api/apiData";
import PopupProductPricesTable from "./Popups/PopupProductPricesTable";
import { replaceProductImageFromSeller } from "../api/apiImage";
import showMessage from "../../../shared/MessagesInfo/message";
import openNotification from "../../../shared/MessagesInfo/WarningBox";

const PopupPrices = (props: PopupPricesProps) => {
    const [productPrices, setProductPrices] = useState<ProductPricesType[]>([]);
    const [loadingData, setLoadingData] = useState(false);
    const [popoverVisible, setPopoverVisible] = useState(false);

    const showProductData = async (visible: boolean) => {
        if (visible) {
            //showProductData(props.record.product_id);
            setLoadingData(true);
            getProductPrices(props.record.product_id, props.currentCountryId).then((resp) => {
                setProductPrices(
                    resp
                        .map((p: { isChekced: boolean }) => ({ ...p, isChecked: false, isImgPriceToProduct: false }))
                        .sort((a: ProductPricesType, b: ProductPricesType) => Number(b.price) - Number(a.price))
                        .sort((a: ProductPricesType, b: ProductPricesType) => b.active_for_website - a.active_for_website)
                );
                props.setProducts((curr) => curr.map((d) => ({ ...d, isPrices: d.product_id === props.record.product_id ? true : false })));
                setPopoverVisible(true);
                setLoadingData(false);
            });
        } else {
            setPopoverVisible(false);
            props.setProducts((curr) => curr.map((p) => ({ ...p, isPrices: false })));
            //setImages([]);
        }
    };

    const setNewDataInTable = (text: string, key: "name" | "shop_name" | "mpn" | "image" | "sku" | "product_model", price_id?: number) => {
        if (key === "sku" || key === "product_model") {
            props.setProducts((curr) => curr.map((d) => (d.product_id === props.record.product_id ? { ...d, name: `${d.name} ${text}` } : d)));
            return;
        }
        if (key === "image") {
            if (text.toLowerCase().includes(".svg")) {
                openNotification("Image format is not allowed. Allowed formats are JPG, JPEG, PNG, GIF and WEBP.");
                return;
            }
            setProductPrices((curr) => curr.map((pp) => (pp.id === price_id ? { ...pp, isImgPriceToProduct: true } : pp)));
            replaceProductImageFromSeller(props.record.product_id, text).then((resp) => {
                props.setProducts((curr) => curr.map((d) => (d.product_id === props.record.product_id ? { ...d, image: resp.data } : d)));
                setProductPrices((curr) => curr.map((pp) => (pp.id === price_id ? { ...pp, isImgPriceToProduct: false } : pp)));
                showMessage("Set New Image");
            });
            return;
        }
        const id = props.record.id;
        props.setProducts((curr) => curr.map((d) => ({ ...d, [key]: d.id === id ? text : d[key as keyof typeof d] /*, isDropdown: false */ })));
    };

    return (
        <Popover
            content={popoverVisible && <PopupProductPricesTable productPrices={productPrices} setProductPrices={setProductPrices} setNewDataInTable={setNewDataInTable} />}
            style={{ paddingTop: "20px", width: "900px" }}
            onOpenChange={showProductData}
            open={popoverVisible}
            placement="bottom"
            title={<span style={{ marginLeft: "0.7rem" }}>Prices</span>}
            trigger="click"
        >
            <div
                style={{
                    cursor: "pointer",
                    width: "100%",
                    height: "100%",
                    position: "relative",
                }}
            >
                Prices
                {loadingData && <Spin size="small" style={{ position: "absolute", right: "10px", top: "16%" }} />}
            </div>
        </Popover>
    );
};

export default PopupPrices;
