import { QuestionCircleOutlined } from "@ant-design/icons";
import { Button, Divider, Modal, Tooltip } from "antd";
import axios from "axios";
import React, { useState } from "react";
import "../style/NavInfoBtn.css";
import { capitalizeFirstLetter } from "../../../screens/attributes/helpers/checkIsIncludes";

interface NavInfoBtnProp {
    path: string;
}

const NavInfoBtn: React.FC<NavInfoBtnProp> = ({ path }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [content, setContent] = useState("");

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
        setContent("");
    };

    const showPageInfo = async () => {
        try {
            const { data } = await axios.get(`${process.env.REACT_APP_URL_API}/intelligence/get-app-info?link=${path === "/" ? "/" : path.substring(1)}`);
            setContent(data.data.content);
        } catch (err) {
            console.log(err);
        }
        setIsModalOpen(true);
    };
    return (
        <React.Fragment>
            <Tooltip placement="bottom" title={"Page Info"} color="#113f67">
                <div id="nav-info-btn" onClick={() => showPageInfo()}>
                    <QuestionCircleOutlined />
                </div>
            </Tooltip>
            <Modal
                title={
                    <span style={{ color: "#007cb9" }}>
                        Page - <i>{capitalizeFirstLetter(`${path === "/" ? "home" : path.substring(1)}`.replaceAll("-", " "))}</i>
                    </span>
                }
                open={isModalOpen}
                onOk={handleOk}
                width={1000}
                onCancel={handleCancel}
                style={{ border: "1px solid lightgray", borderRadius: "5px", padding: "0.5rem", backgroundColor: "whitesmoke" }}
                footer={false}
            >
                {content ? <div dangerouslySetInnerHTML={{ __html: content }} /> : <span style={{ display: "flex", justifyContent: "center", color: "gray" }}>No Description</span>}
                <Divider />
                <Button style={{ marginLeft: "94%" }} type="primary" onClick={handleCancel}>
                    Ok
                </Button>
            </Modal>
        </React.Fragment>
    );
};

export default NavInfoBtn;
