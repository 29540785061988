import React, { useEffect, useRef, useState } from "react";
import { BrandDescStateType } from "./AllBrandsDescription";
import { Button, Col, Form, FormInstance, Input, Row, Typography } from "antd";
import TextEditor from "./TextEditor";

type DescriptionEditorType = {
    country: string;
    loading: boolean;
    brandDescriptionState: BrandDescStateType[];
    setBrandDescriptionState: React.Dispatch<React.SetStateAction<BrandDescStateType[]>>;
    clearTextEditor: number;
    saveDescription: (clear?: boolean, country?: string) => Promise<void>;
    form: FormInstance<any>;
    openEditor: string | undefined;
    setOpenEditor: React.Dispatch<React.SetStateAction<string | undefined>>;
};

const DescriptionEditor: React.FC<DescriptionEditorType> = (props) => {
    const { country, brandDescriptionState, setBrandDescriptionState, saveDescription, openEditor, setOpenEditor } = props;
    const [form] = Form.useForm();
    const [editorText, setEditorText] = useState<string>("");
    const [loadingClearEditor, setLoadingClearEditor] = useState(false);

    const containerRef = useRef(null);

    const clearDescription = () => {
        setLoadingClearEditor(true);
        saveDescription(true, country)
            .then(() => {
                setEditorText("");
                setBrandDescriptionState((curr) => curr.map((c) => (c.country === country ? { ...c, [`title_${country}`]: "", [`h1_${country}`]: "", [`description_${country}`]: "" } : c)));
                setLoadingClearEditor(false);
                form.resetFields();
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const countryData = brandDescriptionState.find((c) => c.country === country);

    useEffect(() => {
        form.setFieldsValue({ [`title_${country}`]: countryData ? countryData[`title_${country}`] : "", [`h1_${country}`]: countryData ? countryData[`h1_${country}`] : "" });
        setEditorText(countryData ? countryData[`description_${country}`] : "");
    }, [countryData, country, form]);

    const onFormBlur = (e: React.FocusEvent<HTMLFormElement, Element>) => {
        console.log(e.target.value);
        setBrandDescriptionState((curr) => curr.map((b) => (b.country === country ? { ...b, [e.target.id]: e.target.value } : b)));
        setEditorText(e.target.value);
    };

    return (
        <div className="all-brands-editor" style={{ marginLeft: "0.3rem" }}>
            <Row>
                <Col span={4}>
                    <Typography.Text code style={{ fontSize: "24px" }}>
                        {country.toUpperCase()}
                    </Typography.Text>
                </Col>
                <Col span={20}>
                    <Form
                        form={form}
                        labelCol={{ span: 6 }}
                        wrapperCol={{ span: 24 }}
                        style={{ border: "1px solid lightgray", borderRadius: "8px", backgroundColor: "#f5f9ee", padding: "0 0.5rem 0.5rem 0" }}
                        onBlur={(e) => onFormBlur(e)}
                    >
                        <Form.Item name={`title_${country}`} style={{ marginBottom: 0, marginTop: 8 }} label={`Title ${country.toUpperCase()}`}>
                            <Input placeholder={`Title ${country.toUpperCase()}`} allowClear />
                        </Form.Item>
                        <Form.Item name={`h1_${country}`} style={{ margin: "0.3rem 0 0 0" }} label={`Custom H1 ${country.toUpperCase()}`}>
                            <Input placeholder={`Custom H1 ${country.toUpperCase()}`} allowClear />
                        </Form.Item>
                    </Form>
                </Col>
            </Row>
            <div style={{ margin: "1rem 0" }}>
                {openEditor === country ? (
                    <TextEditor country={country} editorText={editorText} setBrandDescriptionState={setBrandDescriptionState} containerRef={containerRef} />
                ) : (
                    <React.Fragment>
                        <div className="desc-rev-open-editor-text">Click on text editor to open it.</div>
                        <div
                            onClick={() => setOpenEditor(country)}
                            className="desc-rev-rich-text"
                            dangerouslySetInnerHTML={{ __html: editorText as string }}
                            style={{ padding: "11px 8.2px 0 8.2px", marginTop: "4px", height: "399px", overflow: "auto", border: "1px solid lightgray" }}
                        ></div>
                    </React.Fragment>
                )}
            </div>
            <Row justify="space-between">
                <div />
                <Button type="primary" danger style={{ marginTop: "1rem" }} onClick={() => clearDescription()} loading={loadingClearEditor}>
                    Clear {country.toUpperCase()}
                </Button>
            </Row>
        </div>
    );
};

export default DescriptionEditor;
