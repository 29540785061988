import * as React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import UsersList from "../screens/users/pages/UsersList";
import "antd/dist/reset.css";
import Home from "../screens/home/page/Home";
import MainMaps from "../screens/maps/pages/MainMaps";
import Login from "../screens/users/pages/Login";
import CreateShopRules from "../screens/shops/pages/CreateShopRulesModal";
// import ShopRules from '../screens/shops/pages/ShopRules';
import { useSelector } from "react-redux";
import { RootStore } from "../Store";
import { authCheck } from "../shared/utils/authCheck";
import AllBrands from "../screens/brands/pages/AllBrands";
import AttributeList from "../screens/attributes/pages/AttributeList";
import AllPrices from "../screens/prices/pages/AllPrices";
import CreateAttribute from "../screens/attributes/pages/CreateAttribute";
import EditAttribute from "../screens/attributes/pages/EditAttribute";
import CreatePredefinedText from "../screens/categories/pages/CreatePredefinedText";
import NewMatch from "../screens/logger/pages/NewMatch";
import PricesDifference from "../screens/products/pages/PricesDifference";
import FeedHealthFromTo from "../screens/reports/pages/FeedHealthFromTo";
import AppendReplaceXMLdata from "../screens/shops/pages/AppendReplaceXMLdata";
import XmlMerged from "../screens/maps/pages/XmlMerged";
import DailyReport from "../screens/reports/pages/DailyReport";
import TestPrices from "../wTest/TestPrices";
import PricesAndProducts from "../screens/reports/pages/PricesAndProducts";
import WordReplacement from "../screens/tools/pages/WordReplacement";
import AllCategories from "../screens/categories/pages/AllCategories";
import Comments from "../screens/products/pages/Comments";
import ShopTokPages from "../screens/ShopTokPages/page/ShopTokPage";
import Feeds from "../screens/reports/pages/Feeds";
import ProductImages from "../screens/products/pages/ProductImages";
// import Product from "../screens/products/pages/Product";
import MatchingSellerCategory from "../screens/shops/pages/MatchingSellerCategory";
import FeedChecker from "../screens/reports/pages/FeedChecker";
import UserAssignments from "../screens/users/pages/UserAssignments";
import UserAssignmentsSchedule from "../screens/users/pages/UserAssignmentsSchedule";
import CleanupBrands from "../screens/brands/pages/CleanupBrands";
import UserTasks from "../screens/users/pages/UserTasks";
import UserTaskSettings from "../screens/users/pages/UserTaskSettings";
import AttributeKeyMapping from "../screens/attributes/pages/AttributeKeyMapping";
import AttributeMapping from "../screens/attributes/pages/AttributeMapping";
import ReCategorizing from "../screens/categories/pages/ReCategorizing";
import UsersTaskList from "../screens/users/pages/UsersTaskList";
import ShopList from "../screens/shops/pages/ShopList";
import ShopBrends from "../screens/brands/pages/ShopBrends";
import CategoryPrefSuf from "../screens/categories/pages/CategoryPrefSuff";
import MpnCheck from "../screens/reports/pages/MpnCheck";
import AttributeMappingRules from "../screens/attributes/pages/AttributeMappingRules";
import CreateCatalog from "../screens/catalogs/page/CreateCatalog";
import CatalogList from "../screens/catalogs/page/CatalogList";
import ProductNameMassEditing from "../screens/products/pages/ProductNameMassEditing";
import FAQ from "../screens/categories/pages/FAQ";
import UniversalScraper from "../screens/tools/pages/UniversalScraper";
import AutoMatchedPP from "../screens/products/pages/AutoMatchedPP";
import AutoMatchedOP from "../screens/products/pages/AutoMatchedOP";
import EditUser from "../screens/users/pages/EditUser";
import EanCleaning from "../screens/products/pages/EanCleaning";
import ProductMpnCopy from "../screens/products/pages/EditProductMpn";
import DescriptionReview from "../screens/products/pages/DescriptionReview";
import AllProducts from "../screens/allProducts/pages/AllProducts";
import AttributeOverview from "../screens/attributes/pages/AttributeOverview";
import DescriptionAi from "../screens/products/pages/DescriptionAi";
import EanOverview from "../screens/products/pages/EanOverview";
import TopProductAttributes from "../screens/attributes/pages/TopProductAttributes";
import ProductNew from "../screens/products/pages/ProductNew";
import TopAttributeMapping from "../screens/attributes/pages/TopAttributeMapping";
import ShopAttributes from "../screens/attributes/pages/AttributesAnalytics";
import ScrapersOverview from "../screens/tools/pages/ScrapersOverview";

const Routes: React.FC<any> = ({ setUserActivity }) => {
    const userState = useSelector((state: RootStore) => state);
    if (userState.loggedInUser.user || authCheck()) {
        return (
            <Switch>
                <Route path="/" exact component={Home} />
                <Route path="/user-list" exact component={UsersList} />
                <Route path="/user-assignments" exact component={UserAssignments} />
                <Route path="/user-assignments-schedule" exact component={UserAssignmentsSchedule} />
                <Route path="/users-task-list" exact component={UsersTaskList} />
                <Route path="/shop-list" exact component={ShopList} />
                <Route path="/append-replace-xml-data" exact component={AppendReplaceXMLdata} />
                <Route path="/main-map" exact component={MainMaps} />
                <Route path="/xml-merged" exact component={XmlMerged} />
                {/* <Route path='/shop-rules' exact component={ShopRules} /> */}
                <Route path="/shop-rules/:id" component={CreateShopRules} />
                <Route path="/feed-health-from-to" exact component={FeedHealthFromTo} />
                <Route path="/all-feeds" exact component={Feeds} />
                <Route path="/feed-checker" exact component={FeedChecker} />
                <Route path="/prices-products-report" exact component={PricesAndProducts} />
                <Route path="/product-name-mass-editing" exact component={ProductNameMassEditing} />
                <Route path="/description-review" exact component={DescriptionReview} />
                <Route path="/description-ai" exact component={DescriptionAi} />
                <Route path="/product" exact component={ProductNew} />
                <Route path="/product/:id/:tab" component={ProductNew} />
                <Route path="/prices-difference" exact component={PricesDifference} />
                <Route path="/product-images" exact component={ProductImages} />
                <Route path="/comments-list" exact component={Comments} />
                <Route path="/edit-product-mpn" exact component={ProductMpnCopy} />
                <Route path="/ean-cleaning" exact component={EanCleaning} />
                <Route path="/ean-overview" exact component={EanOverview} />
                <Route path="/automatched-offer-product" exact component={AutoMatchedOP} />
                <Route path="/automatched-product-product" exact component={AutoMatchedPP} />
                <Route path="/category-list" exact component={AllCategories} />
                <Route path="/re-categorizing" exact component={ReCategorizing} />
                <Route path="/create-predefined-text" exact component={CreatePredefinedText} />
                <Route path="/category-prefix-suffix" exact component={CategoryPrefSuf} />
                <Route path="/frequently-asked-questions" exact component={FAQ} />
                <Route path="/matching-seller-category" exact component={MatchingSellerCategory} />
                <Route path="/brand-list" exact component={AllBrands} />
                <Route path="/cleanup-brands" exact component={CleanupBrands} />
                <Route path="/shop-brands" exact component={ShopBrends} />
                <Route path="/attribute-list" exact component={AttributeList} />
                <Route path="/attribute-key-mapping" component={AttributeKeyMapping} />
                <Route path="/attributes-overview" exact component={AttributeOverview} />
                <Route path="/attribute-mapping" exact component={AttributeMapping} />
                <Route path="/attribute-mapping-rules" exact component={AttributeMappingRules} />
                <Route path="/create-attribute" exact component={CreateAttribute} />
                <Route path="/edit-attribute/:id" exact component={EditAttribute} />
                <Route path="/top-product-attributes" exact component={TopProductAttributes} />
                <Route path="/top-attribute-mapping" exact component={TopAttributeMapping} />
                <Route path="/attributes-analytics" exact component={ShopAttributes} />
                <Route path="/price-list" exact component={AllPrices} />
                <Route path="/daily-report" exact component={DailyReport} />
                <Route path="/mpn-check" exact component={MpnCheck} />
                <Route path="/user-logger" exact component={NewMatch} />
                <Route path="/word-replacement" exact component={WordReplacement} />
                <Route path="/universal-scraper/:id" exact component={UniversalScraper} />
                <Route path="/universal-scraper" component={UniversalScraper} />
                <Route path="/scrapers-overview" exact component={ScrapersOverview} />
                <Route path="/shoktok-pages" exact component={ShopTokPages} />
                <Route path="/user-tasks" exact component={UserTasks} />
                <Route path="/edit-user" exact component={EditUser} />
                <Route path="/user-task-settings" exact component={UserTaskSettings} />
                <Route path="/create-catalog" exact component={CreateCatalog} />
                <Route path="/change-catalog/:id/:type" exact component={CreateCatalog} />
                <Route path="/catalog-list" exact component={CatalogList} />
                <Route path="/all-products" exact component={AllProducts} />
                <Route path="/test" exact component={TestPrices} />
                <Redirect to="/" />
            </Switch>
        );
    } else {
        return (
            <Switch>
                <Route path="/" exact component={Home} />
                <Route path="/login" exact component={Login} />
                <Redirect to="/login" />
            </Switch>
        );
    }
};

export default Routes;
