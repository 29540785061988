import { AutoComplete, Button, Divider, Form, Input, Radio, Row, Space, Tooltip } from "antd";
import openNotification from "../../../shared/MessagesInfo/WarningBox";
import { EditableNamePopoverProp } from "../types/props";
import { CloseOutlined, LoadingOutlined, SaveOutlined } from "@ant-design/icons";
import axios from "axios";
import { useEffect, useState } from "react";
import showMessage from "../../../shared/MessagesInfo/message";

const EditableNamePopoverContent: React.FC<EditableNamePopoverProp> = (props) => {
    const [autocompleteWords, setAutocompleteWords] = useState<{ id: number; name: string }[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [deleteWord, setDeleteWord] = useState<number | undefined>();
    const [form] = Form.useForm();

    useEffect(() => {
        const getAutocompleteWords = async () => {
            const baseUrl = process.env.REACT_APP_URL_API;
            try {
                const { data } = await axios.get(`${baseUrl}/product/autocomplete-words/get`);
                setAutocompleteWords(data.data);
            } catch (err) {
                console.log(err);
            }
        };
        props.popoverVisible && getAutocompleteWords();
    }, [props.popoverVisible]);

    const addText = () => {
        //props.setPopoverVisible(false);
        const oldText = form.getFieldValue("old-text");
        const newText = form.getFieldValue("new-text");
        const textPosition = form.getFieldValue("textPosition") as "start" | "end" | "replace" | "replace_all";
        switch (textPosition) {
            case "start":
                props.setProducts((curr) => curr.map((p) => (p.isChecked ? { ...p, name: newText + ` ${p.name}` } : p)));
                break;
            case "end":
                props.setProducts((curr) => curr.map((p) => (p.isChecked ? { ...p, name: `${p.name} ` + newText } : p)));
                break;
            case "replace":
                props.setProducts((curr) => curr.map((p) => (p.isChecked ? { ...p, name: `${p.name}`.replace(oldText, newText) } : p)));
                break;
            case "replace_all":
                props.setProducts((curr) => curr.map((p) => (p.isChecked ? { ...p, name: newText } : p)));
                break;
            default:
                openNotification("Select text position!");
                break;
        }

        form.resetFields();
    };

    const saveAutocompleteWord = async () => {
        setLoading(true);
        const baseUrl = process.env.REACT_APP_URL_API;
        try {
            const { data } = await axios.put(`${baseUrl}/product/autocomplete-words/upsert`, { name: form.getFieldValue("new-text") });
            setAutocompleteWords((curr) => [...curr, data.data /*{ id: data.data.id.toString(), name: data.data.name }*/]);
            showMessage("Saved new autocomplete word");
            setLoading(false);
        } catch (err) {
            console.log(err);
        }
    };

    const deleteAutocompleteWord = async (event: any, id: number) => {
        event.stopPropagation();
        setDeleteWord(id);
        const baseUrl = process.env.REACT_APP_URL_API;
        try {
            await axios.delete(`${baseUrl}/product/autocomplete-words/delete?id=${id}`);
            setAutocompleteWords((curr) => curr.filter((word) => word.id !== id));
            showMessage("Word deleted");
            setDeleteWord(undefined);
            //console.log(data.data);
        } catch (err) {
            console.log(err);
        }
    };

    const textPosition = Form.useWatch("textPosition", form);
    const newText = Form.useWatch("new-text", form);

    return (
        <div style={{ display: "flex", flexDirection: "column", marginTop: "1.5rem" }}>
            <Divider style={{ marginTop: 0, marginBottom: 10, padding: 0 }} />
            <Form onFinish={addText} initialValues={{ textPosition: "start" }} form={form}>
                {textPosition === "replace" && (
                    <Form.Item label="Old Text" name="old-text" rules={[{ required: true, message: "Missing old text" }]}>
                        <Input placeholder="Old Text" />
                    </Form.Item>
                )}
                <Form.Item label="New Text">
                    <Space.Compact style={{ width: "100%" }}>
                        <Form.Item name="new-text" noStyle rules={[{ required: true, message: "New text is required!" }]}>
                            <AutoComplete
                                allowClear
                                // style={{ width: 200 }}
                                options={autocompleteWords.map((word) => ({
                                    value: word.name,
                                    label: (
                                        <div key={word.name} style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                            <span>{word.name}</span>
                                            <Tooltip title={`Delete ${word.name}`}>
                                                {deleteWord === word.id ? (
                                                    <LoadingOutlined />
                                                ) : (
                                                    <CloseOutlined className="delete-autocomplete-words" onClick={(e) => deleteAutocompleteWord(e, word.id)} />
                                                )}
                                            </Tooltip>
                                        </div>
                                    ),
                                }))}
                                placeholder="Enter text"
                                filterOption={(inputValue, option) => option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
                            />
                        </Form.Item>
                        <Form.Item /*name={["address", "street"]}*/ noStyle>
                            <Button
                                icon={<SaveOutlined />}
                                onClick={saveAutocompleteWord}
                                disabled={autocompleteWords.map((word) => word.name.trim().toLowerCase()).includes(newText?.trim().toLowerCase())}
                                loading={loading}
                            />
                        </Form.Item>
                    </Space.Compact>
                </Form.Item>

                <Form.Item key="modelPosition" label="Position" name="textPosition">
                    <Radio.Group name="radiogroup">
                        <Radio value={"start"}>Start</Radio>
                        <Radio value={"end"}>End</Radio>
                        <Radio value={"replace"}>Replace</Radio>
                        <Radio value={"replace_all"}>Replace All</Radio>
                    </Radio.Group>
                </Form.Item>
                <Divider style={{ marginTop: 0, marginBottom: 10, padding: 0 }} />
                <Row justify="center">
                    <Button type="primary" htmlType="submit">
                        Add text
                    </Button>
                </Row>
            </Form>
        </div>
    );
};

export default EditableNamePopoverContent;
