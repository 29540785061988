import axios from "axios";
import { AttributeType, CategoryType, ShopType, ValueType } from "../../types/AttributesAnalytics.type";

const baseUrl = process.env.REACT_APP_URL_API;

export const showShops = async (isOpen: boolean, record: CategoryType, setData: React.Dispatch<React.SetStateAction<CategoryType[]>>) => {
    if (isOpen) {
        try {
            const { data } = await axios.get(`${baseUrl}/prices/attributes/list-new-attributes-shops?l3=${record.attribute_category_id}`);

            setData((curr) =>
                curr.map((cat) => (cat.key === record.key ? { ...cat, isOpen: true, shops: data.data.map((d: Omit<ShopType, "key">) => ({ ...d, key: d.main_shop_id, attributes: [] })) } : cat))
            );
        } catch (err) {
            console.log(err);
        }
    } else {
        setData((curr) => curr.map((cat) => (cat.key === record.key ? { ...cat, isOpen: false, shops: [] } : cat)));
    }
};

export const showAttributes = async (isOpen: boolean, record: ShopType, categoryKey: number, setData: React.Dispatch<React.SetStateAction<CategoryType[]>>) => {
    if (isOpen) {
        try {
            const { data } = await axios.get(`${baseUrl}/prices/attributes/list-new-attribute-by-l3?l3_id=${record.attribute_category_id}&shop_id=${record.main_shop_id}`);

            setData((curr) =>
                curr.map((cat) =>
                    cat.key === categoryKey
                        ? {
                              ...cat,
                              shops: cat.shops.map((shop) =>
                                  shop.key === record.key ? { ...shop, isOpen: true, attributes: data.data.map((d: Omit<ShopType, "key">, i: number) => ({ ...d, key: i + 1, values: [] })) } : shop
                              ),
                          }
                        : cat
                )
            );
        } catch (err) {
            console.log(err);
        }
    } else {
        setData((curr) =>
            curr.map((cat) =>
                cat.key === categoryKey
                    ? {
                          ...cat,
                          shops: cat.shops.map((shop) => (shop.key === record.key ? { ...shop, isOpen: false, attributes: [] } : shop)),
                      }
                    : cat
            )
        );
    }
};

export const showValues = async (isOpen: boolean, record: AttributeType, categoryKey: number, shopKey: number, setData: React.Dispatch<React.SetStateAction<CategoryType[]>>) => {
    if (isOpen) {
        try {
            const { data } = await axios.get(
                `${baseUrl}/prices/attributes/list-new-attributes-by-key?l3_id=${record.attribute_category_id}&key=${record.attribute_key}&shop_id=${record.main_shop_id}`
            );

            setData((curr) =>
                curr.map((cat) =>
                    cat.key === categoryKey
                        ? {
                              ...cat,
                              shops: cat.shops.map((shop) =>
                                  shop.key === shopKey
                                      ? {
                                            ...shop,
                                            attributes: shop.attributes.map((attr) =>
                                                attr.key === record.key ? { ...attr, isOpen: true, values: data.data.map((d: ValueType, i: number) => ({ ...d, key: i + 1 })) } : attr
                                            ),
                                        }
                                      : shop
                              ),
                          }
                        : cat
                )
            );
        } catch (err) {
            console.log(err);
        }
    } else {
        setData((curr) =>
            curr.map((cat) =>
                cat.key === categoryKey
                    ? {
                          ...cat,
                          shops: cat.shops.map((shop) =>
                              shop.key === shopKey
                                  ? {
                                        ...shop,
                                        attributes: shop.attributes.map((attr) => (attr.key === record.key ? { ...attr, isOpen: false, values: [] } : attr)),
                                    }
                                  : shop
                          ),
                      }
                    : cat
            )
        );
    }
};
