import { Button, Col, Divider, Empty, Layout, Row, Statistic, Table } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import HelmetTitle from "../../../shared/Head/HelmetTitle";
import axios from "axios";
import EansModal from "../components/EanOverview/EansModal";
import "../style/EanOverview.css";
import Filters from "../components/EanOverview/Filters";
import { useSelector } from "react-redux";
import { RootStore } from "../../../Store";
import numberWithCommas from "../../../shared/utils/numberUtils";

export type OffersType = { product_name: string; brand: string; mpn: string; shop: string; price_id: number; correctPrice: boolean; checkedPrice: boolean };

export type EansType = {
    data: { code: string; id: number; loadingNewProductByEAN: boolean; loadingNewProductByOffer: boolean; offers: OffersType[] }[];
    product_id: number | undefined;
    eanInfo: Record<string, string | number>;
};

export type CategoryTreeType = {
    // parent: null
    name: string;
    id: number;
    count: number;
    l2_name: string;
    children: {
        parent: number;
        name: string;
        id: number;
        count: number;
        l2_name: string;
    }[];
};

let initCategories: CategoryTreeType[] = [];

let categoryWithZero: { category: CategoryTreeType; level: number }[] = [];

const EanOverview = () => {
    const [productList, setProductList] = useState<Record<string, string | number>[]>([]);
    const [categories, setCategories] = useState<CategoryTreeType[]>([]);
    const [count, setCount] = useState(0);
    const [selectedCategory, setSelectedCategory] = useState<string | undefined>();
    const [loadingCategories, setLoadingCategories] = useState(false);
    const [checked, setChecked] = useState<"0" | "1" | undefined>();
    const [order, setOrder] = useState<"asc" | "desc" | undefined>();
    const [orderBy, setOrderBy] = useState<"1" | "2" | "3" | undefined>();
    const [productId, setProductId] = useState<string | undefined>();
    const [page, setPage] = useState(1);
    const [eans, setEans] = useState<EansType>({ data: [], product_id: undefined, eanInfo: {} });
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    const stateProduct = useSelector((state: RootStore) => state);

    const currentCountryName = stateProduct.dataLs.country ? stateProduct.dataLs.country.currentCountry : "rs";

    const getCategories = useCallback(async () => {
        setLoadingCategories(true);
        try {
            const baseUrl = process.env.REACT_APP_URL_API;
            const { data } = await axios.get(
                `${baseUrl}/product/ean-overview/list-grouped-categories${productId ? `?product_id=${productId}` : ""}${checked ? `?checked=${checked}` : ""}${
                    productId ? `&product_id=${productId}` : ""
                }`
            ); // l3=229
            const allCategories: CategoryTreeType[] = data.data;

            setCategories((curr) => {
                const l4Categories = allCategories.flatMap((obj) => obj.children);

                const selectedCategoryId = Number(selectedCategory);

                initCategories.forEach((l3category) => {
                    // Selected L3
                    if (l3category.id === selectedCategoryId) {
                        const checkCategory = allCategories.find((l3: CategoryTreeType) => l3.id === selectedCategoryId);
                        const findCategoryInData = curr.find((l3: CategoryTreeType) => l3.id === selectedCategoryId);

                        if (!checkCategory && findCategoryInData) {
                            categoryWithZero.push({
                                category: {
                                    ...l3category,
                                    count: 0,
                                    children: l3category.children ? l3category.children.map((l4) => ({ ...l4, count: 0 })) : [],
                                },
                                level: 3,
                            });
                        }
                    }

                    // Selected L4
                    l3category.children?.forEach((l4category) => {
                        let tempL3: CategoryTreeType | undefined;
                        if (l4category.id === selectedCategoryId) {
                            const findCountInL3 = allCategories.find((l3) => l3.id === l3category.id);
                            tempL3 = findCountInL3;
                            const checkCategory = l4Categories.find((l4) => l4?.id === selectedCategoryId);
                            const findCategoryInData = curr.flatMap((obj) => obj.children).find((l4) => l4?.id === selectedCategoryId);
                            if (!checkCategory && findCategoryInData) {
                                categoryWithZero.push({
                                    category: {
                                        ...l3category,
                                        count: tempL3 ? tempL3.count : 0,
                                        children: [...(tempL3?.children ? [...tempL3.children] : []), { ...l4category, count: 0 }],
                                    },
                                    level: 4,
                                });
                            }
                        }
                    });
                });

                if (categoryWithZero && categoryWithZero.length > 0) {
                    initCategories = [...allCategories, categoryWithZero[0].category];
                    return [...allCategories.filter((c3) => c3.id !== categoryWithZero[0].category.id), categoryWithZero[0].category];
                } else {
                    initCategories = allCategories;
                    return allCategories;
                }
            });

            categoryWithZero = [];

            setLoadingCategories(false);
        } catch (err) {
            console.log(err);
        }
    }, [productId, checked, selectedCategory]);

    useEffect(() => {
        getCategories();
    }, [getCategories]);

    useEffect(() => {
        // for count
        if (selectedCategory) {
            categories.some((l3) => {
                if (l3.id === Number(selectedCategory)) {
                    setCount(l3.count);
                    return l3.count;
                }
                l3.children?.some((l4) => {
                    if (l4.id === Number(selectedCategory)) {
                        setCount(l4.count);
                        return l4.count;
                    }
                    return false;
                });
                return false;
            });
        } else {
            setCount(0);
        }
    }, [categories, selectedCategory]);

    const getData = useCallback(async () => {
        setLoading(true);
        try {
            const baseUrl = process.env.REACT_APP_URL_API;
            const { data } = await axios.get(
                `${baseUrl}/product/ean-overview/list-products-by-l3?page=${page}${selectedCategory ? `&l3=${selectedCategory}` : ""}${checked ? `&checked=${checked}` : ""}${
                    order ? `&order_by=${order}` : ""
                }${orderBy ? `&order_by=${orderBy}` : ""}${productId ? `&product_id=${productId}` : ""}`
            ); // l3=229
            setProductList(data.data);
            setLoading(false);
        } catch (err) {
            setLoading(false);
            console.log(err);
        }
    }, [selectedCategory, checked, order, orderBy, productId, page]);

    useEffect(() => {
        (selectedCategory || productId) && getData();
    }, [selectedCategory, productId, getData]);

    const showEans = async (record: Record<string, string | number>) => {
        try {
            const baseUrl = process.env.REACT_APP_URL_API;
            const { data } = await axios.get(`${baseUrl}/product/ean-overview/list-product-eans?productID=${record.id}`); /// ${record.id}    5360472
            //console.log(data);
            setEans({
                data: [
                    ...data.data
                        .map((d: { code: string; offers: string }) => ({
                            ...d,
                            key: d.code,
                            loadingNewProductByEAN: false,
                            loadingNewProductByOffer: false,
                            offers: [...JSON.parse(d.offers).map((d: Record<string, string>) => ({ ...d, correctPrice: false, checkedPrice: false }))],
                        }))
                        .sort((a: { offers: string }, b: { offers: string }) => b.offers.length - a.offers.length),
                    ...(data[`non-offers`]
                        ? [
                              {
                                  // insert prices without enas
                                  brand_name: "",
                                  code: "0000000000000",
                                  id: 0,
                                  image_url: "",
                                  key: "0000000000000",
                                  loadingNewProductByEAN: false,
                                  loadingNewProductByOffer: false,
                                  [`name_${currentCountryName}`]: "no name",
                                  offers: data[`non-offers`], // prices without eans
                              },
                          ]
                        : []),
                ],
                product_id: Number(record.id),
                eanInfo: record,
            });
            setIsModalOpen(true);
        } catch (err) {
            console.log(err);
        }
    };

    const onChangePage = (e: number) => {
        setPage(e);
    };

    // console.log(productId);

    return (
        <Row justify="center">
            <HelmetTitle title="Data Entry App - Ean Overview " />
            <Col span={12} className="automatched-prices-box" style={{ minHeight: "90vh", marginBottom: "2rem" }}>
                <Divider>Ean Overview</Divider>
                <Row justify="space-between">
                    <Filters
                        category={{ selectedCategory, setSelectedCategory }}
                        cheched={{ checked, setChecked }}
                        order={{ order, setOrder }}
                        orderBy={{ orderBy, setOrderBy }}
                        setProductList={setProductList}
                        setEans={setEans}
                        categories={categories}
                        loadingCategories={loadingCategories}
                        setPage={setPage}
                        setProductId={setProductId}
                    />
                    <Statistic
                        title="Total"
                        value={count && numberWithCommas(String(count).replace(".", ","))}
                        valueStyle={{ color: "green" }}
                        style={{ marginRight: "1rem" }}
                        loading={loadingCategories}
                    />
                </Row>
                <EansModal eans={eans} setEans={setEans} setProductList={setProductList} isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} getData={getData} />
                <Layout style={{ margin: "0.6rem 1rem", minHeight: "70%" }}>
                    <Layout.Header className="ean-overview-header" />
                    {selectedCategory || productId ? (
                        <Table
                            loading={loading}
                            columns={[
                                {
                                    title: "Id",
                                    dataIndex: "id",
                                    align: "center" as "center",
                                    width: "50px",
                                },
                                {
                                    title: "Product",
                                    dataIndex: "name",
                                },
                                {
                                    title: "Ean Count",
                                    align: "center" as "center",
                                    dataIndex: `ean_count`,
                                    width: "100px",
                                },
                                {
                                    title: "",
                                    dataIndex: `id`,
                                    align: "center" as "center",
                                    width: "100px",
                                    render: (text, record) => (
                                        <Button size="small" onClick={() => showEans(record)}>
                                            Show Eans
                                        </Button>
                                    ),
                                },
                            ]}
                            dataSource={productList}
                            pagination={{ pageSize: 100, total: count, showSizeChanger: false, onChange: onChangePage, current: page }}
                            rowKey={(record) => record.id}
                        />
                    ) : (
                        <Empty
                            style={{ marginTop: "30%" }}
                            description={
                                <div>
                                    For list products,<span style={{ color: "darkred" }}> Select category</span>
                                </div>
                            }
                        />
                    )}
                </Layout>
            </Col>
        </Row>
    );
};

export default EanOverview;
