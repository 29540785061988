import React, { useEffect, useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { ExpandRowDataType, FlagStatus } from "../../pages/DescriptionReview";
import "../../style/TextEditor.css";

type TextEditorType = {
    country: string;
    type: "preview" | "review";
    expandRowData: ExpandRowDataType | null;
    setExpandRowData: React.Dispatch<React.SetStateAction<ExpandRowDataType | null>>;
    containerRef: any;
};

const TextEditor: React.FC<TextEditorType> = (props) => {
    const { country, type, expandRowData, setExpandRowData, containerRef } = props;

    const init = expandRowData ? expandRowData[type as keyof ExpandRowDataType][type === "preview" ? `preview_text_${country}` : `review_content_${country}`] : "";
    const [editorData, setEditorData] = useState(init?.toString());
    useEffect(() => {
        setEditorData(expandRowData ? expandRowData[type as keyof ExpandRowDataType][type === "preview" ? `preview_text_${country}` : `review_content_${country}`]?.toString() : "");
    }, [expandRowData, country, type]);
    const handleEditorChange = (event: any, editor: ClassicEditor) => {
        const data = editor.getData();
        setEditorData(data);
    };

    const changeDataOnBlur = (event: any, editor: ClassicEditor) => {
        const newText = editor.getData();
        const clearInit = init?.toString() ?? "";
        if (clearInit.trim() === newText.trim()) {
            return;
        }
        setExpandRowData((curr) =>
            curr
                ? {
                      ...curr,
                      [type]: {
                          ...curr[type],
                          [type === "preview" ? `preview_text_${country}` : `review_content_${country}`]: newText.trim(),
                          ...(type === "preview" && {
                              [`flag_${country}`]: FlagStatus.MANUAL,
                          }),
                      },
                  }
                : null
        );
    };
    const handleBeforeDestroy = () => {
        const container = containerRef.current;
        if (container) {
            // Zaključavanje dimenzija kontejnera pre uklanjanja
            container.style.height = `${container.offsetHeight}px`;
            container.style.width = `${container.offsetWidth}px`;
            container.style.overflow = "hidden"; // Sprečava prelivanje
        }
    };

    return (
        <div ref={containerRef}>
            <CKEditor
                editor={ClassicEditor}
                data={editorData ?? ""}
                onChange={handleEditorChange}
                onBlur={changeDataOnBlur}
                onReady={(editor) => {
                    // Postavljanje fokusa kad se editor učita
                    editor.editing.view.focus();
                    // Event handler za čišćenje pre uništavanja
                    const originalDestroy = editor.destroy.bind(editor);
                    editor.destroy = () => {
                        handleBeforeDestroy(); // Podesi stilove
                        return originalDestroy();
                    };
                }}
            />
        </div>
    );
};

export default TextEditor;
