import { Col, Input, Row, Select, TreeSelect } from "antd";
import React, { ChangeEvent } from "react";
import { CategoryTreeType, EansType } from "../../pages/EanOverview";

interface FiltersProps {
    category: { selectedCategory: string | undefined; setSelectedCategory: React.Dispatch<React.SetStateAction<string | undefined>> };
    cheched: { checked: "0" | "1" | undefined; setChecked: React.Dispatch<React.SetStateAction<"0" | "1" | undefined>> };
    order: { order: "asc" | "desc" | undefined; setOrder: React.Dispatch<React.SetStateAction<"asc" | "desc" | undefined>> };
    orderBy: { orderBy: "1" | "2" | "3" | undefined; setOrderBy: React.Dispatch<React.SetStateAction<"1" | "2" | "3" | undefined>> };
    setProductList: React.Dispatch<React.SetStateAction<Record<string, string | number>[]>>;
    setEans: React.Dispatch<React.SetStateAction<EansType>>;
    categories: CategoryTreeType[];
    loadingCategories: boolean;
    // setCount: React.Dispatch<React.SetStateAction<number>>;
    setPage: React.Dispatch<React.SetStateAction<number>>;
    setProductId: React.Dispatch<React.SetStateAction<string | undefined>>;
}

const Filters = (props: FiltersProps) => {
    const { category, cheched, order, orderBy, setProductList, setEans, categories, loadingCategories, setPage, setProductId } = props;

    const changeCategory = (e: string) => {
        if (!e) {
            setProductList([]);
            setEans({ data: [], product_id: undefined, eanInfo: {} });
            cheched.setChecked(undefined);
            order.setOrder(undefined);

            category.setSelectedCategory(e);
        } else {
            category.setSelectedCategory(e);
        }
        setPage(1);
    };

    let delayTimer: ReturnType<typeof setTimeout>;
    const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
        clearTimeout(delayTimer);
        delayTimer = setTimeout(function () {
            setProductId(e.target.value);
            //props.setSearch(e.target.value);
            // props.setPage(1);
            setPage(1);
        }, 1000);
    };

    return (
        <Row justify="space-between" align="middle" className="ean-cleaning-box">
            <div className="ean-cleaning-filter-box">
                <TreeSelect
                    showSearch
                    style={{ width: "100%", marginTop: "10px" }}
                    placeholder="Select Category"
                    allowClear
                    onChange={changeCategory}
                    treeDefaultExpandAll={false}
                    value={category.selectedCategory}
                    loading={loadingCategories}
                    filterTreeNode={(search: string, item: any) => {
                        return item?.title?.props?.children[0].toLowerCase().indexOf(search.toLowerCase()) >= 0;
                    }}
                    treeData={categories.map((c3) => ({
                        title: (
                            <span style={{ display: "flex" }}>
                                {`${c3?.id}: ${c3?.name} `} <span style={{ fontSize: "12px", opacity: "0.8", color: "gray", marginLeft: "0.3rem" }}> {`(${c3.l2_name}) `}</span>
                                <div style={{ marginLeft: "0.5rem" }}>{`${c3?.count}`}</div>
                            </span>
                        ),
                        value: c3?.id,
                        children: c3.children
                            ? c3.children.map((c4) => ({
                                  title: `${c4.id}: ${c4.name} ${c4.count}`,
                                  value: c4.id,
                              }))
                            : [],
                    }))}
                />
                <Row>
                    <Col span={6}>
                        <Select
                            style={{ width: "145px", marginTop: "10px" /*, marginRight: "7px"*/ }}
                            allowClear
                            placeholder="Status"
                            onChange={(e) => {
                                cheched.setChecked(e);
                                setPage(1);
                            }}
                            value={cheched.checked}
                            options={[
                                { value: "1", label: "Checked" },
                                { value: "0", label: "Not Cheched" },
                            ]}
                        />
                    </Col>
                    <Col span={6}>
                        <Select
                            style={{ width: "145px", marginTop: "10px", marginRight: "7px" }}
                            allowClear
                            placeholder="Order"
                            onChange={(e) => {
                                order.setOrder(e);
                                orderBy.setOrderBy(undefined);
                                setPage(1);
                            }}
                            value={order.order}
                            options={[
                                { value: "asc", label: "Ascending" },
                                { value: "desc ", label: "Descending" },
                            ]}
                        />
                    </Col>
                    <Col span={6}>
                        <Select
                            style={{ width: "145px", marginTop: "10px" }}
                            allowClear
                            placeholder="Order By"
                            onChange={(e) => {
                                orderBy.setOrderBy(e);
                                order.setOrder(undefined);
                                setPage(1);
                            }}
                            value={orderBy.orderBy}
                            options={[
                                { value: "1", label: "1 + " },
                                { value: "2", label: "2 + " },
                                { value: "3", label: "3 + " },
                            ]}
                        />
                    </Col>
                    <Col span={6}>
                        {/* <Form form={form} onFinish={searchByProduct} onBlur={searchByProductBlur}>
                            <Form.Item name="product_id"> */}
                        <Input onChange={handleSearch} placeholder="Product id" style={{ width: "145px", marginTop: "10px" }} />
                        {/* </Form.Item>
                        </Form> */}
                    </Col>
                </Row>
            </div>
            {/* <Card className="ean-cleaning-card-statistic">
        <Statistic title="Total" value={count !== null ? numberWithCommas(count) : ""} valueStyle={{ color: "green" }} loading={countLoading} />
    </Card> */}
        </Row>
    );
};

export default Filters;
