import { ScraperType } from "../../pages/ScrapersOverview";
import React, { useContext, useEffect, useRef, useState } from "react";
import type { InputRef } from "antd";
import { Button, Form, Input, Spin, Table } from "antd";
import openNotification from "../../../../shared/MessagesInfo/WarningBox";
import axios from "axios";
import showMessage from "../../../../shared/MessagesInfo/message";
import { Loading3QuartersOutlined } from "@ant-design/icons";
import moment from "moment";

interface ScrapersOverviewTableProps {
    scrapers: ScraperType[];
    setScrapers: React.Dispatch<React.SetStateAction<ScraperType[]>>;
    showHistory: (id: number) => Promise<void>;
    setPage: React.Dispatch<React.SetStateAction<number>>;
    count: number;
}

const EditableContext = React.createContext<any>(null);

interface EditableRowProps {
    index: number;
}

const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
    const [form] = Form.useForm();
    return (
        <Form form={form} component={false}>
            <EditableContext.Provider value={form}>
                <tr {...props} />
            </EditableContext.Provider>
        </Form>
    );
};

interface EditableCellProps {
    title: React.ReactNode;
    editable: boolean;
    dataIndex: keyof ScraperType;
    record: ScraperType;
    handleSave: (record: ScraperType) => void;
}

const EditableCell: React.FC<React.PropsWithChildren<EditableCellProps>> = ({ title, editable, children, dataIndex, record, handleSave, ...restProps }) => {
    const [editing, setEditing] = useState(false);
    const [loading, setLoading] = useState<"run_at" | "website_url" | undefined>(undefined);
    const inputRef = useRef<InputRef>(null);
    const form = useContext(EditableContext)!;

    useEffect(() => {
        if (editing) {
            inputRef.current?.focus();
        }
    }, [editing]);

    const toggleEdit = () => {
        setEditing(!editing);
        form.setFieldsValue({ [dataIndex]: record[dataIndex] });
    };

    const save = async () => {
        try {
            const values = await form.validateFields();

            if (record[dataIndex]?.toString().trim() === values[dataIndex]?.trim()) {
                // input is not changed
                toggleEdit();
                return;
            }

            if (dataIndex === "run_at" && values.run_at && values.run_at.trim().length > 0) {
                const timeRegex = /^(0[0-9]|1[0-9]|2[0-3]):([0-5][0-9])$/;
                if (!timeRegex.test(values.run_at)) {
                    openNotification("Time should be in format HH:MM (00:00 - 24:00)");
                    setLoading(undefined);
                    return;
                }
            }

            setLoading(dataIndex as "run_at" | "website_url" | undefined);

            const { data } = await axios.put(`${process.env.REACT_APP_URL_API}/scraper/data/update-scraper`, { id: record.id, [dataIndex]: values[dataIndex] });
            showMessage(data.message);
            toggleEdit();
            handleSave({ ...record, ...values });
            setLoading(undefined);
            // }
        } catch (errInfo) {
            console.log("Save failed:", errInfo);
        }
    };

    let childNode = children;

    if (editable) {
        childNode = editing ? (
            <Form.Item style={{ margin: 0 }} name={dataIndex}>
                <Input
                    ref={inputRef}
                    onPressEnter={save}
                    onBlur={save}
                    suffix={dataIndex === loading ? <Spin size="small" indicator={<Loading3QuartersOutlined style={{ fontSize: 14 }} spin />} /> : <span />}
                />
            </Form.Item>
        ) : (
            <div className="editable-cell-value-wrap" style={{ paddingInlineEnd: 24, minHeight: "32px", borderRadius: "8px" }} onClick={toggleEdit}>
                {children}
            </div>
        );
    }

    return <td {...restProps}>{childNode}</td>;
};

const ScrapersOverviewTable = (props: ScrapersOverviewTableProps) => {
    const { scrapers, setScrapers, showHistory, setPage, count } = props;

    const defaultColmns = [
        { title: "Id", dataIndex: "id", width: "60px" },
        { title: "Name", dataIndex: "website_name", editable: true },
        { title: "Website url", dataIndex: "website_url", editable: true, width: "300px" },
        {
            title: (
                <div style={{ display: "flex" }}>
                    Run at
                    <span style={{ opacity: 0.5, marginLeft: "0.2rem" }}>(Format 00:00 - 24:00)</span>
                </div>
            ),
            dataIndex: "run_at",
            editable: true,
            // align: "center" as "center",
            width: "230px",
        },
        { title: "Last run", dataIndex: "last_run", width: "200px", render: (text: string) => text && `${moment(text).format("DD MMM YYYY,")} ${moment(text).format("HH:mm")}` },
        {
            title: "Hisotry",
            dataIndex: "id",
            width: "80px",
            render: (id: number) => (
                <Button size="small" onClick={() => showHistory(id)}>
                    History
                </Button>
            ),
        },
        {
            title: "Parameters",
            dataIndex: "id",
            width: "80px",
            align: "center" as "center",
            render: (id: number) => (
                <Button size="small" onClick={() => window.open(`/universal-scraper/${id}`, "_blank", "noreferrer")}>
                    Edit
                </Button>
            ),
        },
    ];

    const handleSave = (row: ScraperType) => {
        const newData = [...scrapers];
        const index = newData.findIndex((item) => row.id === item.id);
        const item = newData[index];
        newData.splice(index, 1, {
            ...item,
            ...row,
        });
        setScrapers(newData);
    };

    const components = {
        body: {
            row: EditableRow,
            cell: EditableCell,
        },
    };

    const columns = defaultColmns.map((col) => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record: ScraperType) => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                handleSave,
            }),
        };
    });

    return (
        <Table
            components={components}
            rowClassName={() => "editable-row"}
            bordered
            dataSource={scrapers}
            columns={columns as any}
            rowKey={(record) => record.id}
            pagination={{ pageSize: 100, onChange: setPage, total: count, showSizeChanger: false }}
        />
    );
};

export default ScrapersOverviewTable;
