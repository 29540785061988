import { Row, Spin } from "antd";
import { DescriptionReviewType, ExpandRowDataType } from "../../pages/DescriptionReview";
import React, { useState } from "react";
import { RootStore } from "../../../../Store";
import AllDescriptions from "./AllDescriptions";
import AllReviews from "./AllReviews";

type ExpandedRowContentType = {
    record: DescriptionReviewType;
    loading: boolean;
    state: RootStore;
    expandRowData: ExpandRowDataType | null;
    setExpandRowData: React.Dispatch<React.SetStateAction<ExpandRowDataType | null>>;
    getCategories: () => Promise<void>;
    setDescRevData: React.Dispatch<React.SetStateAction<DescriptionReviewType[]>>;
    currentCountryName: string;
};

export type OpenEditorType = { type: "description" | "review"; country: string } | undefined;

const ExpandedRowContent: React.FC<ExpandedRowContentType> = (props) => {
    const [openEditor, setOpenEditor] = useState<OpenEditorType>();
    const { record, loading, state, expandRowData, setExpandRowData, getCategories, setDescRevData, currentCountryName } = props;

    return loading ? (
        <Row justify="center">
            <Spin />
        </Row>
    ) : (
        <React.Fragment>
            <AllDescriptions
                record={record}
                state={state}
                expandRowData={expandRowData}
                setExpandRowData={setExpandRowData}
                getCategories={getCategories}
                setDescRevData={setDescRevData}
                currentCountryName={currentCountryName}
                openEditor={openEditor}
                setOpenEditor={setOpenEditor}
            />
            <AllReviews
                state={state}
                expandRowData={expandRowData}
                setExpandRowData={setExpandRowData}
                record={record}
                getCategories={getCategories}
                setDescRevData={setDescRevData}
                currentCountryName={currentCountryName}
                openEditor={openEditor}
                setOpenEditor={setOpenEditor}
            />
        </React.Fragment>
    );
};

export default ExpandedRowContent;
